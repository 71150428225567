import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  secondaryTitle: {
    background: '#C45824', // Theme non reconnu dans les styles de pages
    padding: theme.spacing(3),
    alignSelf: 'flex-start',
    margin: `${theme.spacing(3)}px 0`
  },
  moreVideos: {
    color: "#000",
    textTransform: 'initial'
  },
  freePdf: {
    width: 800,
    height: 900,
    marginBottom: "5%",
    '@media (max-width:900px)': {
      width: 400,
      height: 450
    },
    '@media (max-width:400px)': {
      width: 300,
      height: 350
    },
  },
  downloadBox: {
    display: 'none',
    '@media (max-width:900px)': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '5%'
    },
  },
  downloadTitle:{
    fontWeight: 600
  },
  icon: {
    color: '#C45824'
  },
}));
