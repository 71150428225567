import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/layout';
import CourseCard from '../components/CourseCard/courseCard';
import CustomLoader from '../components/CustomLoader';
import Hero from '../components/Hero';
import Subtitle from '../components/Subtitle';
import CustomButton from '../components/CustomButton/customButton';

import { getContentTypeEntriesLaunched } from '../state/reducer';
import { formatDateToFrenchFormat, formatTimestampToDateWithDashes, addDaysToTimestamp, addProtocol, formatHour } from '../utils/format';
import styles from '../pagesStyles/formationsStyles';
import { KNOW_MORE, TRAINING_SESSIONS_LIMIT } from '../utils/constants';

const FormationsPage = ({ data }) => {
  const dispatch = useDispatch();
  const { title, paragraph, freeSessionTitle, coverImage, moreVideosTitle, SEOtitle, SEOdescription } = data.texts.frontmatter;
  const pdf = data?.contentfulPdfFormationGratuite?.pdf?.file?.url;
  const classes = styles();

  const { contentTypeEntries, isLoading } = useSelector(state => ({
    contentTypeEntries: state.getIn(['root', 'contentTypeEntries']),
    isLoading: state.getIn(['root', 'isLoading'])
  }));

  // To display only future sessions, calculate tomorrow's date to pass to the saga filters
  // Contentful returns a GB-formatted date, so with can keep this format for comparison
  const tomorrow = addDaysToTimestamp(Date.now(), 1);
  const formattedDate = formatTimestampToDateWithDashes(tomorrow);

  useEffect(() => {
    dispatch(getContentTypeEntriesLaunched({ id: 'formation', date: formattedDate, limit: TRAINING_SESSIONS_LIMIT }));
  }, []);

  const renderSessionsList = () => (
    contentTypeEntries.length > 0
      ? contentTypeEntries.map((session, key) => {
        const sessionDetails = session.fields;
        const image = sessionDetails?.image?.fields?.file?.url;
        const localizedDate = formatDateToFrenchFormat(sessionDetails.date) + " à " + formatHour(sessionDetails.date);
        return <CourseCard
          key={key}
          title={sessionDetails.title}
          image={image}
          author={sessionDetails.name}
          description={sessionDetails.excerpt}
          slug={sessionDetails.slug}
          date={localizedDate}
          allProps={session}
        />
      })
      : <Typography>Pas de formations prévues pour le moment</Typography>
  )

  return (
    <Layout>
      <Helmet>
        <html lang="fr" />
        <title>{SEOtitle}</title>
        <meta name="description" content={SEOdescription} />
      </Helmet>

      <Hero image={coverImage.childImageSharp.gatsbyImageData} title={title} alt={title} />
      <Subtitle text={paragraph} />
      <Grid container direction="column" alignItems="center">
        {isLoading
          ? <CustomLoader />
          : renderSessionsList()}
        <Typography variant="h2" className={classes.secondaryTitle}>{freeSessionTitle}</Typography>
        <Box m={2}>
          <Grid container direction="column" alignItems="center">
            <iframe src={pdf} title='pdf' className={classes.freePdf} />
            <Grid className={classes.downloadBox}>
              <Box mr={3}>
                <Typography className={classes.downloadTitle}>Ouvrir la plaquette en pdf</Typography>
              </Box>
              <a href={pdf} target="_blank" rel="noreferrer" download>
                <FontAwesomeIcon icon={faFilePdf} size="3x" className={classes.icon} />
              </a>
            </Grid>
            <Typography variant="h2" className={classes.moreVideos}>{moreVideosTitle}</Typography>
            <Box my={3}>
              <CustomButton link={addProtocol(`${process.env.GATSBY_ADHERENT_URL}/formations`, "https")} isOutsideLink text={KNOW_MORE} />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Layout>
  )
}

export default FormationsPage

export const query = graphql`
  query {
    texts: markdownRemark(fileAbsolutePath: { regex: "/formations.md/" }) {
      frontmatter {
        SEOtitle
        SEOdescription
        title
        paragraph
        freeSessionTitle
        moreVideosTitle
        coverImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    contentfulPdfFormationGratuite {
      pdf {
        file {
          url
        }
      }
    }
  }
`
