import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  fullContainer: {
    paddingBottom: '5%'
  },
  container: {
    height: '35vh',
    '@media (max-width:400px)': {
      display: 'none'
    },
  },
  cover: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '35vh',
  },
}));
