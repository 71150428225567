import React from 'react';
import styles from './styles';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import Title from '../../components/Title/index';

const Hero = ({ image, title, alt }) => {

  const classes = styles();

  return (
    <div className={classes.fullContainer}>
      <div className={classes.container}>
        {typeof image === "object"
          ? <GatsbyImage image={image} alt={alt} className={classes.cover} />
          : <img alt={alt} src={image} className={classes.cover} />
        }
      </div>
      {title && <Title title={title} />}
    </div>
  );
}

export default Hero

Hero.propTypes = {
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  alt: PropTypes.string
};