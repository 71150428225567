import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  typo: {
    backgroundColor: ({ isButton }) => isButton ? theme.palette.secondary.main : theme.palette.primary.mainBGColor,
    textTransform: 'uppercase',
  },
  color: {
    color: ({ isButton }) => isButton ? theme.palette.secondary.main : theme.palette.primary.mainBGColor
  }
}));
