import React from 'react'
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import styles from './styles';

const CustomLoader = ({ isButton }) => {
  const classes = styles({ isButton });
  return <CircularProgress size={isButton ? 20 : 80}
    classes={{ colorPrimary: classes.color }}
    disableShrink={!isButton}
  />
}

export default CustomLoader

CustomLoader.propTypes = {
  isButton: PropTypes.bool,
};

CustomLoader.defaultProps = {
  isButton: false,
};