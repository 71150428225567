import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  wrapper: {
    width: '100%',
  },
  root: {
    display: 'flex',
    borderRadius: 0
  },
  media: {
    height: 250,
  },
  cardContainer: {
    width: '100%'
  },
  cardTitle: {
    textTransform: theme.typography.h2.textTransform,
    fontSize: 20,
    fontWeight: 600,
    textAlign: 'center',
    backgroundColor: theme.palette.primary.mainBGColor,
    color: theme.palette.secondary.main,
    '@media (max-width:1550px)': {
      fontSize: 16
    },
  },
  bold: {
    fontWeight: 'bold'
  },
  generalInfos: {
    padding: `0 ${theme.spacing(2)}px`
  }
}));
